import React from "react";

const Header = () => (
  <header>
    <h1>
      <span className="header-span upper-span">
        Welche Tasse {" "}
        <br />
      </span>
      <span className="header-span lower-span">ist dein Typ ?</span>
    </h1>
  </header>
);

export default Header;
