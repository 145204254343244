import React from "react";
import likeimg from "../../assets/images/dislike.png"

const Dislike = ({ questionId, modifySuperficialChoices }) => (
    <button type="button"
            onClick={() => modifySuperficialChoices(questionId, 'ADD_TO_DISLIKED')}
    >
        <img src={likeimg} alt="Likeimg" />
    </button>
);

export default Dislike;
