import options from "./options";

// self note: make this better ffs

const getResults = (liked, disliked) => {
  let result = options[0];

  //console.log(liked.some(x => x.id === 0));

  //check if liked contains answers
  if (
    liked.some(e => e.id === 0) &&
    liked.some(e => e.id === 1) &&
    liked.some(e => e.id === 2)
  ) {
    result = options[1];
  } else if (
    disliked.some(e => e.id === 0) &&
    disliked.some(e => e.id === 1) &&
    disliked.some(e => e.id === 2)
  ) {
    result = options[0];
  } else if (
    liked.some(e => e.id === 0) &&
    liked.some(e => e.id === 1) &&
    disliked.some(e => e.id === 2)
  ) {
    result = options[1];
  } else if (
    disliked.some(e => e.id === 0) &&
    liked.some(e => e.id === 1) &&
    disliked.some(e => e.id === 2)
  ) {
    result = options[1];
  } else if (
    disliked.some(e => e.id === 0) &&
    liked.some(e => e.id === 1) &&
    liked.some(e => e.id === 2)
  ) {
    result = options[1];
  } else if (
    liked.some(e => e.id === 0) &&
    disliked.some(e => e.id === 1) &&
    disliked.some(e => e.id === 2)
  ) {
    result = options[2];
  } else if (
    liked.some(e => e.id === 0) &&
    disliked.some(e => e.id === 1) &&
    liked.some(e => e.id === 2)
  ) {
    result = options[2];
  } else if (
    disliked.some(e => e.id === 0) &&
    disliked.some(e => e.id === 1) &&
    liked.some(e => e.id === 2)
  ) {
    result = options[2];
  }

  return result;
};

export default getResults;
