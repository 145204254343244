import React from "react";
import Dislike from "./actions/Dislike";
import Like from "./actions/Like";

const Actions = ({ question, modifySuperficialChoices }) => (
  <div id="actions">
    <Dislike
      questionId={question.id}
      modifySuperficialChoices={modifySuperficialChoices}
    />
    <Like
      questionId={question.id}
      modifySuperficialChoices={modifySuperficialChoices}
    />
  </div>
);

export default Actions;
