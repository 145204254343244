import React, { useState } from "react";
import "./App.css";
import Header from "./components/Header";
import CartItem from "./components/CardItem";
import data from "./data.json";
import getResults from "./getResults";
import Result from "./components/Result";

function App() {
  const [questions, setQuestions] = useState(data);
  const [likedQuestions, setLikedQuestions] = useState([]);
  const [dislikedQuestions, setDislikedQuestions] = useState([]);

  //change to state variable!!
  let result;

  let actualQuestion = 0;

  const removedQuestionFromDataSrc = (dataSource, questionId) =>
    dataSource.filter(question => question.id !== questionId);

  const modifySuperficialChoices = (questionId, action) => {
    const newQuestion = [...questions];
    const newLikedQuestions = [...likedQuestions];
    const newDislikedQuestions = [...dislikedQuestions];

    // solve the condition
    if (questions.length !== 1) {
      //console.log(questions.length > 0);
      //console.log("length" + questions.length);
      switch (action) {
        case "ADD_TO_LIKED":
          if (!likedQuestions.includes(questionId)) {
            newLikedQuestions.push(newQuestion[actualQuestion]);

            setLikedQuestions(newLikedQuestions);
            setQuestions(removedQuestionFromDataSrc(questions, questionId));
            actualQuestion++;
          }
          break;
        case "ADD_TO_DISLIKED":
          if (!dislikedQuestions.includes(questionId)) {
            newDislikedQuestions.push(newQuestion[actualQuestion]);

            setDislikedQuestions(newDislikedQuestions);
            setQuestions(removedQuestionFromDataSrc(questions, questionId));
            actualQuestion++;
          }
          break;
        default:
          return questions;
      }
    }
  };

  result = getResults(likedQuestions, dislikedQuestions);

  return (
    <div className="App">
      <Header />
      {questions[1] ? (
        <CartItem
          question={questions[actualQuestion]}
          modifySuperficialChoices={modifySuperficialChoices}
        />
      ) : (
        <Result result={result} />
      )}
    </div>
  );
}

export default App;
