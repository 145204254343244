import React from "react";
import likeimg from "../../assets/images/like.png"

const Like = ({ questionId, modifySuperficialChoices }) => (
  <button type="button"
          onClick={() => modifySuperficialChoices(questionId, 'ADD_TO_LIKED')}
  >
    <img src={likeimg} alt="Likeimg" />
  </button>
);

export default Like;
