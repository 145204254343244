import React from "react";
import Actions from "./Actions";

const CardItem = ({ question, modifySuperficialChoices }) => {
  const { text, image } = question;
  //console.log("card" + question.id);

  return (
    <>
      <div className="card-wrapper__outer">
        <div className="card-wrapper">
          <div
            className="card"
            style={{ backgroundImage: `url(/cardImages/${image})` }}
          >
            <div className="card-description">
              <p className="card-paragraph">{text}</p>
            </div>
          </div>
          <Actions
            question={question}
            modifySuperficialChoices={modifySuperficialChoices}
          />
        </div>
      </div>
    </>
  );
};

export default CardItem;
