import React from "react";
import "../App.css";

const Result = result => {
  console.log(result.result);
  const { link, text, image } = result.result;

  return (
    <>
      <div className="result-page-wrapper__outer">
        <div className="title">IT'S A CATCH!</div>
        <div className="tasse-image-wrapper">
          <img className="tasse-img" src={`/${image}`} alt="tasse" />
        </div>
        <div className="result-paragrahp">
          <p>{text}</p>
        </div>
        <a
          href="https://www.thefemalecompany.com/shop/produkt/menstruationscup/"
          alt="shop now"
          className="shop-button"
        >
          Lernt euch kennen!
        </a>
      </div>
    </>
  );
};

export default Result;
